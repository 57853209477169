import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import WistiaPopoverVideo from '../../../components/WistiaPopoverVideo';

import './style.scss';

const Page = () => {
    return (
        <Layout navTheme="light">
            <Seo title="Vendor Onboarding - Selecting the Right Partner" />
            <div className="selecting-the-right-partner-page">
                <Container className="container-1">
                    <Row className="center">
                        <Col className="centered-text header-text">
                            <h1 className="white-text max-width-900 margin-auto hero-text">
                                Selecting The Right Partner for your Program
                            </h1>
                        </Col>
                    </Row>
                    <Row className="center video-row">
                        <Col className="centered-text max-width-900 video">
                            <WistiaPopoverVideo id={'yfpe5fukv0'}>
                                <div className="video-wrapper">
                                    <img
                                        className="play-button"
                                        src={require('../../../images/vendor-onboarding/play-button.svg')}
                                        alt="play button"
                                    />
                                    <img
                                        className="thumbnail"
                                        src={require('../../../images/vendor-onboarding/selecting-the-right-partner/thumbnail.png')}
                                        alt="Selecting the right partner video thumbnail"
                                    />
                                </div>
                            </WistiaPopoverVideo>
                        </Col>
                    </Row>
                    <img
                        className="white-divider"
                        src={require('../../../svgs/common/white-divider.svg')}
                        alt="white divider"
                    />
                </Container>
                <Container className="container-2">
                    <Row className="introduction-row">
                        <Col className="max-width-900">
                            <p>
                                In this guide, we will discuss some of the
                                traits we look for in partners that have a
                                higher chance of success in a lead generation
                                partner program. This is part 3 of our four-part
                                series built to help you maximize your partner
                                program results.
                            </p>
                        </Col>
                    </Row>
                    <Row className="partner-program-row">
                        <Col className="max-width-900">
                            <h1 class="title large">Partner program leads</h1>
                            <p>
                                In this program your primary lead type will be
                                top of funnel leads meaning they are showing
                                interest through activities but are not
                                necessarily a “please call me to take my money”
                                form submission. The form submissions may come
                                but we suggest that partners selected for this
                                program should be able to drive sales velocity
                                by proactively engaging with leads as they
                                interact with your content via, emails, assets
                                and landing pages. Partners in this program will
                                have selected prospects that meet their basic
                                MQL qualifications such as: company size,
                                vertical, location, and contact type prior to
                                any campaigns being launched. When leads are
                                generated in Honey they will be from the
                                pre-selected prospects that are now leads
                                because they have taken actions to click, read,
                                and/or submitted a form to your marketing
                                content.
                            </p>
                        </Col>
                    </Row>
                    <Row className="right-partner-row">
                        <Col className="max-width-900">
                            <h1 class="title large">
                                So how do I find the right partner?
                            </h1>
                            <p>
                                There are a few commonalities between the
                                partners we have seen be successful with
                                converting leads from partner programs over the
                                years. Some of these traits include:
                            </p>
                            <ul>
                                <li>Sales oriented (hungry)</li>
                                <li>Active/willing partner</li>
                                <li>Matching lead definition</li>
                                <li>Established sales process</li>
                            </ul>
                            <p>
                                Let’s talk a bit about each trait, how they
                                apply, what to look for.
                            </p>
                        </Col>
                    </Row>
                    <Row className="sales-oriented-row">
                        <Col className="max-width-900">
                            <h3 className="title small">
                                Sales oriented (hungry)
                            </h3>
                            <p>
                                This is a bit of an obvious one, but still
                                important to look for in a partner. Your
                                partners are all in different stages in their
                                business journey and while all seem to be
                                interested in generating more revenue, their
                                actions are not always aligned. Because of the
                                nature of the top funnel leads you are looking
                                for someone who isn’t afraid to do some leg
                                work. Granted these will be leads that are
                                engaging with your content, but that does not
                                exclude them for the activities work required to
                                convert a lead to a closed sale. Look for
                                indicators that they are in a growth pattern and
                                are tenacious or go getters.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="max-width-900">
                            <p className="pink-callout">
                                Quick note: You may be tempted to look for a
                                partner with the highest volume in sales, but be
                                cautious in many situations high volume partners
                                employ inside sales to manage the volume and
                                they are typically not hunters.
                            </p>
                        </Col>
                    </Row>
                    <Row className="active-partner-row">
                        <Col className="max-width-900">
                            <h3 className="title small">
                                Active/willing partner
                            </h3>
                            <p>
                                A lot of this program is white glove but it’s no
                                secret you will need willing participants in the
                                program. Partners will have to answer a few
                                questions, be willing to jump on calls, and
                                initiate a few requirements to ensure the
                                program’s success. While we do have methods to
                                mitigate and ensure a campaign release if a
                                partner is not as cooperative, this of course is
                                never ideal and the results are usually similar.
                                Look for a partner who has engaged with you and
                                is open to communication. They should be excited
                                to generate leads.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="max-width-900">
                            <p className="pink-callout">
                                Quick Note: One requirement needed is DKIM
                                (Domain Keys Identified Mail), this requirement
                                needs the partner to update their A record so
                                that emails that are sent from GlassHive are in
                                fact authorized by the partner. This greatly
                                increases email deliverability and engagement in
                                the program.
                            </p>
                        </Col>
                    </Row>
                    <Row className="matching-lead-row">
                        <Col className="max-width-900">
                            <h3 className="title small">
                                Matching Lead Definition
                            </h3>
                            <p>
                                Ask your partner “what is a lead to you?” “What
                                is a MQL for you?” “Do you categorize your
                                leads?” These are very important questions
                                because it will let you know their willingness
                                to engage in follow up with the majority of
                                leads in the program. Partners will get to
                                select important qualifiers for their potential
                                leads to ensure quality and incentivize partners
                                to follow up. However, some partners may want to
                                wait for specific action form submissions being
                                the most popular. While they do happen, we
                                suggest that “working for a paycheck is better
                                than waiting to win the lottery.” A proactive
                                approach always yields better results. We
                                recommend a strong follow up process for leads
                                that have generated one or multiple activities.
                            </p>
                        </Col>
                    </Row>
                    <Row className="established-sales-row">
                        <Col className="max-width-900">
                            <h3 className="title small">
                                Established sales process
                            </h3>
                            <p>
                                Ask a partner a few status questions about their
                                sales process like “what is your current process
                                to convert a lead into a sale?” “How many
                                activities on average do you find it takes to
                                convert a lead?” “What is your follow up
                                process?” These questions go a long way to help
                                you understand the sales maturity of a partner.
                                What you should be looking for is consistency in
                                their process and an emphasis in sales activity
                                generation.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="max-width-900">
                            <p className="pink-callout">
                                Quick note: The average lead requires 8-12
                                follow up activities to convert it into a closed
                                sale.
                            </p>
                        </Col>
                    </Row>
                    <Row className="conclusion-row">
                        <Col className="max-width-900">
                            <p>
                                In order to keep this information simple to
                                consume and easy to remember, we have split
                                these onboarding topics into a series. You
                                should expect to receive the next topic soon but
                                if you would like to keep reading please click
                                on any of the following links below.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="vendor-onboarding-wrapper max-width-900">
                            <Link
                                to="/vendor-onboarding/expectations"
                                className="vendor-onboarding part"
                            >
                                Part 1: Expectations
                            </Link>
                            <Link
                                to="/vendor-onboarding/content-selection"
                                className="vendor-onboarding part"
                            >
                                Part 2: Program Content selection
                            </Link>
                            <Link
                                to="/vendor-onboarding/ensure-ongoing-success"
                                className="vendor-onboarding part"
                            >
                                Part 4: Ensuring Ongoing Success
                            </Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="contact-us-col max-width-900">
                            <p>
                                Please don’t hesitate to{' '}
                                <a
                                    href="mailto:info@glasshive.com"
                                    className="contact-us"
                                >
                                    contact us
                                </a>{' '}
                                if you have any questions.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Page;
